//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default BaseCmsBlock.extend({
  name: "SpecialBlock",
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: (this?.values?.interval_seconds || 4) * 1000,
          disableOnInteraction: false,
        },
        slidesPerView: "auto",
        pagination: {
          el: ".tm-swiper-pagination",
        },
        loop: true,
        breakpoints: {
          0: {
            spaceBetween: 12,
          },
          768: {},
          1000: {
            spaceBetween: 12,
          },
          1500: {},
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },

  computed: {},

  methods: {
    hasText(slide) {
      return Boolean(slide.text);
    },
    getSlideTitle: function (slide) {
      return slide?.title || "";
    },
    getSlideText: function (slide) {
      return slide?.text || "";
    },
    getSlideImage: function (slide) {
      if (!Number.parseFloat(slide.is_gif || 0)) {
        return String(slide?.image || "");
      }

      const extraSlide = (this.extras?.items || []).find((t) => t.image === slide.image);
      return extraSlide?.image_link
    },
    getSlideLink: function (slide) {
      return slide?.link || "#";
    },
    getSlideTypeLink: function (slide) {
      const link = slide?.link || "";

      var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      var regex = new RegExp(expression);
      if (link.match(regex)) {
        return "a"
      }

      return 'nuxt-link';
    },
  },
});
